var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c(
        "Card",
        [
          _c("p", { attrs: { slot: "title" }, slot: "title" }, [
            _vm.type == 0
              ? _c("span", [_vm._v("新增请假申请")])
              : _vm.type == 1
              ? _c("span", [_vm._v("编辑请假申请")])
              : _c("span", [_vm._v("查看请假申请详情")]),
          ]),
          _c(
            "Row",
            [
              _c(
                "Form",
                {
                  ref: "form",
                  staticStyle: { position: "relative" },
                  attrs: {
                    model: _vm.form,
                    "label-width": 100,
                    rules: _vm.formValidate,
                  },
                },
                [
                  _vm.type != 0 && _vm.type != 1
                    ? _c(
                        "FormItem",
                        { attrs: { label: "请假申请人", prop: "title" } },
                        [_vm._v(_vm._s(_vm.form.createBy))]
                      )
                    : _vm._e(),
                  _c(
                    "FormItem",
                    { attrs: { label: "请假类型", prop: "type" } },
                    [
                      _c(
                        "Select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.dictType, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.title))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("Input", {
                        staticStyle: { width: "550px" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    { attrs: { label: "原因", prop: "description" } },
                    [
                      _c("Input", {
                        staticStyle: { width: "550px" },
                        attrs: {
                          type: "textarea",
                          rows: 5,
                          placeholder: "请输入详细情况",
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    { attrs: { label: "请假时间范围", error: _vm.errorMsg } },
                    [
                      _c("DatePicker", {
                        staticStyle: { width: "550px" },
                        attrs: {
                          options: _vm.options,
                          type: "datetimerange",
                          format: "yyyy-MM-dd HH:mm",
                          clearable: "",
                          placeholder: "选择起始时间",
                        },
                        on: { "on-change": _vm.selectDateRange },
                        model: {
                          value: _vm.selectDate,
                          callback: function ($$v) {
                            _vm.selectDate = $$v
                          },
                          expression: "selectDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("FormItem", { attrs: { label: "请假时长" } }, [
                    _vm._v(_vm._s(_vm.form.duration) + " 小时"),
                  ]),
                  _c(
                    "FormItem",
                    { staticClass: "br" },
                    [
                      _vm.type == 0 || _vm.type == 1
                        ? _c(
                            "Button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.submitLoading,
                              },
                              on: { click: _vm.handelSubmit },
                            },
                            [_vm._v("保存并关闭")]
                          )
                        : _vm._e(),
                      _vm.type != 0
                        ? _c(
                            "Button",
                            {
                              attrs: { type: "info" },
                              on: { click: _vm.handelPrint },
                            },
                            [_vm._v("打印")]
                          )
                        : _vm._e(),
                      _c("Button", { on: { click: _vm.handelCancel } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                  _vm.loading
                    ? _c("Spin", { attrs: { size: "large", fix: "" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }